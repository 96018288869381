
@import-normalize;
.container {
    width: 100%;
    height: 100vh;
}

.buttons {
    background-color: red;
    color: red;
}

.image{
    border-radius: 2em;
}