.alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.alertInner{
    position: relative;
    padding:32px;
    width: 100%;
    max-width: 640px;
    background-color: rgb(255, 255, 255);
    border-radius: 2em;
    -webkit-box-shadow: 5px 7px 35px 5px rgba(0,0,0,0.51); 
    box-shadow: 5px 7px 35px 5px rgba(0,0,0,0.51);
}

.alertInner .closeBtn{
    position: absolute;
    top:16px;
    right: 16px;
    border-radius: 2em;
    color: white;
    background-color: rgb(202, 34, 34);
}