@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');
@import-normalize;

.containerCreate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    height: 100vh;
    background-image: url("https://wallpaperaccess.com/full/3952024.png");
    background-size: 100vw;
  }
  
  label {
    padding: 4px 6px;
    line-height: 190%;
    outline-style: none;
    -webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
    font-family: 'Raleway', sans-serif;
  }
  
  h1{
    font-family: 'Roboto Condensed', sans-serif;
    margin-bottom: 1em;
    color: black;
  }

  .column{

  }

  .logo{

  }

  .form {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 30px 25px;
    background-color: #f2f2f2;
    border-radius: 40px;
    vertical-align: top;
  }
  
  .separado {
    display: inline-block;
    padding-right: 100px;
    color: rgb(34, 31, 31);
    columns: 2;
    -webkit-column-gap: 5em;
       -moz-column-gap: 5em;
            column-gap: 5em;
    -webkit-column-break-inside: avoid;
       -moz-column-break-inside: avoid;
            break-inside: avoid;
    -moz-columns: 2;
    break-inside:avoid ;
    -webkit-columns: 2;
    -webkit-break-inside: avoid;
  }

  .tipos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    color: rgb(34, 31, 31) ;
    padding-top: 5em;
  }
  
  .tipos label {
  }
  
  .hiddenCB div {
    display: inline;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .hiddenCB h1{
    color: rgb(34, 31, 31) ;
    font-family: 'Roboto Condensed', sans-serif;
  }
  
  .hiddenCB input[type="checkbox"],
  .hiddenCB input[type="radio"] {
    display: none;
  }
  
  .hiddenCB label {
    cursor: pointer;
    border-radius: 50px;
    font-family: 'Raleway', sans-serif;
  }
  
  .hiddenCB input[type="checkbox"] + label:hover {
    background: rgb(233, 35, 0);
  }
  
  .hiddenCB input[type="checkbox"]:checked + label {
    background: rgba(235, 52, 46, 0.4);
  }
  
  .hiddenCB input[type="checkbox"]:checked + label:hover {
    background: rgba(0, 128, 128, 0, 0.7);
  }
  
  .question {
    position: relative;
    padding: 5px 0;
  }
  .question2 {
    position: relative;
    padding: 5px 0;
    padding-top: 1.2109em;

  }

  .question:first-of-type {
  }
  
  .question:last-of-type {
  }
  
  .question label {
    font-weight: 100;
    letter-spacing: 0.01em;
    font-size: 17px;
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
  }

  .question2 label {
    font-weight: 100;
    letter-spacing: 0.01em;
    font-size: 17px;
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
  }
  
  .danger label {
    font-weight: 100;
    letter-spacing: 0.01em;
    font-size: 17px;
    color: red;
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
  }

  .dangerText{
    font-size: 1em;
    letter-spacing: 0.05em;
  }
  
  form .danger input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    text-align: center;
    background-color: none;
    border: 1px solid rgba(240, 1, 1, 0.89);
    line-height: 0;
    font-size: 17px;
    width: 100%;
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 60px;
    color: black;
    font-weight: 500;
    letter-spacing: 0.01em;
    z-index: 1;
  }
  
  form .question input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    text-align: center;
    background-color: none;
    border: 1px solid rgba(71, 62, 62, 0.815);
    line-height: 0;
    font-size: 17px;
    width: 100%;
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 0.5em;
    color: black;
    font-weight: 500;
    letter-spacing: 0.01em;
    z-index: 1;
  }
  
  .question input:focus {
    outline: none;
    background: rgba(255, 244, 196, 0.767);
    color: rgb(77, 76, 76);
  }

  form .question2 input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    text-align: center;
    background-color: none;
    border: 1px solid rgba(71, 62, 62, 0.815);
    line-height: 0;
    font-size: 17px;
    width: 100%;
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 0.5em;
    color: black;
    font-weight: 500;
    letter-spacing: 0.01em;
    z-index: 1;
  }
  
  .question2 input:focus {
    outline: none;
    background: rgba(255, 244, 196, 0.767);
    color: rgb(77, 76, 76);
  }
  
  .submit {
    margin-top: 200px;
    padding: 0.6em 0.8em;
    width: 100px;
    margin-right: 20px;
    background-color: rgb(177, 3, 3);
    color: rgb(255, 255, 255);
    border: none;
    cursor: pointer;
    border-radius: 40px;
  }

  .submit:hover{
    background-color: red;
  }

  .preview{
    height: 108px;
    width: 190px;
  }

  @media (max-width : 500px){

    .containerCreate{

      background-image: none;
    }

    h1{
      font-size: 1.3rem;
      line-height: 1.1;
      letter-spacing: 0.007em;
      font-weight: 400;
      margin-right: 3em;
      
    }
    .form {
      margin-top: 20em;
      width: 80vw;
    }
    
    .separado {
      columns: 1;
      -webkit-column-break-inside: avoid;
         -moz-column-break-inside: avoid;
              break-inside: avoid;
      -moz-columns: 1;
      -webkit-columns: 1;
      -webkit-break-inside: avoid;
      -webkit-column-gap: 1em;
         -moz-column-gap: 1em;
              column-gap: 1em;
    }

    .logo img{
      height: 50%;
      width: 50%;
    }

    .hiddenCB div{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .tipos{
      flex-direction: column;
    }
    .submit{
      width: 60px;
    }
  }