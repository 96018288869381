.container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100vh;
    background-image: url("background2.png");
}

.container h1 {
    padding-top: 175px;
    font-size: 2.5em;
    font-weight: 500;
    letter-spacing: 8px;
}

.container h1 span {
    position: relative;
    font-weight: 800;
    color: #f8cb11;
    -webkit-text-stroke: 0.1vw #464646;
}
.container h1 span::before{
    content: attr(data-text);
    position: absolute;
    margin-top: 0.12em;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    color: #d60303;
    -webkit-text-stroke: 0vw #464646;
    border-right: 2px solid #d60303;
    overflow: hidden;
    animation: animate 6s linear infinite;
}

@keyframes animate
{
    0%
    {
        width: 0;
    }
    70%,90%
    {
        width: 100%;
    }
}

.container p {
    padding-top: 50px;
    letter-spacing: 1px;
    font-weight: 400;
}

.container input {
    margin-top: 50px;
}

.myButton {
	box-shadow: 3px 7px 0px 1px #b91600;
	background:linear-gradient(to bottom, #cf1800 5%, #cf1800 100%);
	background-color:#d60303;
	border-radius:5px;
	border:1px solid #d60303;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding:9px 25px;
	text-decoration:none;
	text-shadow:0px 2px 0px #b91600;
}
.myButton:hover {
	background:linear-gradient(to bottom, #f7341b 5%, #f7341b 100%);
	background-color:#ff1e00;
}
.myButton:active {
	position:relative;
	top:1px;
}


.container img {
    margin-top: 120px;
    height: 75%;
}

.love {
    left: 10px;
    bottom: 0;
    font-weight: 200;
    font-size: 1em;
    position: absolute;
}