@import-normalize;
.header {
  display:-ms-grid;
  display:grid;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 6em;
  top: 2em;
  width: 100vw;
  margin-top: -30px;
  background-color: #f2cb07;
  -webkit-box-shadow: 0px 10px 10px 0px rgba(33, 33, 33, 0.35);
  box-shadow: 0px 10px 10px 0px rgba(33, 33, 33, 0.35);
}


.header .logo img {
  position:relative;
  margin-top: 2em ;
  margin-left: 4em;
  width: 160px;
  height: 60px;
}

*, *::before, *::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  background-image: var(--background-image);
  color: white;
}

:root {
  --icon-size: 2rem;
  --indicator-spacing: calc(var(--icon-size) / 8);
  --border-radius: calc(var(--icon-size) / 4);
  --nav-item-padding: calc(var(--icon-size) / 2);
  --background-image: url("wallpaper.jpg")
}

.navbar_container {
  display: inline-block;
  position: absolute;
  background-color: white;
  border-radius: var(--border-radius);
  margin-top: 4em;
  padding: 0 calc(var(--nav-item-padding) * 1.5);
  -ms-grid-column-align: center ;
      justify-self: center ;
  
}

.list {
  font-size: 1.3em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.list a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: var(--nav-item-padding);
}

.list .text {
  font-size: .8em;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  position: absolute;
  bottom: calc(.5 * var(--nav-item-padding));
  -webkit-transform: translateY(50%);
      -ms-transform: translateY(50%);
          transform: translateY(50%);
}

.list .icon {
  position: relative;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.list .icon img {
  fill: currentColor;
  width: var(--icon-size);
  height: var(--icon-size);
  display: block;
}

.list .active .text {
  pointer-events: all;
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

.list .active .icon {
  -webkit-transform: translateY(calc(-50% - var(--nav-item-padding)));
      -ms-transform: translateY(calc(-50% - var(--nav-item-padding)));
          transform: translateY(calc(-50% - var(--nav-item-padding)));
}

.list {
  position: relative;
}

.indicator {
  position: absolute;
  left: calc(var(--position) * (var(--icon-size) + var(--nav-item-padding) * 2));
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.indicator::after,
.indicator::before {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.indicator::after {
  background-color: hsl(0, 0%, 17%);
  width: calc(var(--icon-size) * 2);
  height: calc(var(--icon-size) * 2);
  top: calc(-1 * var(--icon-size));
}
.indicator::before {
  background-color: #f2cb07;
  width: calc((var(--icon-size) + var(--indicator-spacing)) * 2);
  height: calc((var(--icon-size) + var(--indicator-spacing)) * 2);
  top: calc(-1 * var(--icon-size) - var(--indicator-spacing));
  left: calc(-1 * var(--indicator-spacing));
}

.corners::before {
  content: "";
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  position: absolute;
  width: var(--border-radius);
  height: var(--border-radius);
  background-color: white;
  z-index: 1;
  top: calc(-1 * var(--indicator-spacing));
  left: calc(.2 * var(--indicator-spacing));
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%);
  border-top-right-radius: 100%;
  border-width: calc(var(--indicator-spacing));
  border-color:#f2cb07;
  border-style: solid;
  border-bottom: none;
  border-left: none;
}

.corners::after {
  content: "";
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  position: absolute;
  width: var(--border-radius);
  height: var(--border-radius);
  background-color: white;
  z-index: 1;
  top: calc(-1 * var(--indicator-spacing));
  left: calc(var(--icon-size) * 2 + -.2 * var(--indicator-spacing));
  border-top-left-radius: 100%;
  border-width: calc(var(--indicator-spacing));
  border-color: #f2cb07;
  border-style: solid;
  border-bottom: none;
  border-right: none;
}

.image{
    height: 0.5rem;
    width: 0.5rem;
}

@media (max-width : 500px){
  .header{
  }
  .header .logo img {
    width: 75px;
    height: 30px;
    margin-left: 0.5em;
    margin-top: 3em;
  }

}
